@font-face {
  font-family: "iconfont"; /* Project id 2551758 */
  src: url('./iconfont.woff2?t=1621869438545') format('woff2'),
       url('./iconfont.woff?t=1621869438545') format('woff'),
       url('./iconfont.ttf?t=1621869438545') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-spin:before {
  content: "\e851";
}

.icon-fangdajing:before {
  content: "\e60d";
}

.icon-Aa:before {
  content: "\e636";
}

.icon-line-quillpenyumaobi:before {
  content: "\e6eb";
}
